@import '~app/mixins';

.checkoutSummary {
  color: $color-grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  .input {
    display: block;
    padding: 5px 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: inset 0 1px 0 0 #ececec;
    font-size: 14px;
    width: 100%;
    color: $color-grey-dark;
  }

  .paymentTop {
    display: flex;
    width: 100%;

    .rateDescription {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 115px;
      margin: 0 10px;

      .rateIcon {
        fill: #5cbd8d;
        height: 50px;
        margin-bottom: 10px;
      }

      .rate {
        display: flex;

        > :first-child {
          font-size: 34px;
          font-weight: bold;
          margin-right: 6px;
        }

        > :last-child {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-top: 4px;

          > :last-child {
            font-size: 10px;
          }
        }
      }

      .quantity {
        font-weight: bold;
        white-space: nowrap;
        font-size: 12px;
      }
    }

    .instructions {
      font-size: 14px;
      line-height: 22px;
      white-space: pre-wrap;
      text-align: left;
      margin-bottom: 10px;
    }
  }

  .paymentBottom {
    width: 450px;

    .order {
      width: 100%;
      border: 1px solid #d8dbdf;
      border-radius: 8px;
      padding: 12px 20px;
      margin-top: 20px;
      text-align: left;
      background-color: $color-alabaster;

      .smallTitle {
        text-decoration: underline;
      }

      .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .contacts {
        margin: 10px 0;
      }

      .omitted {
        font-size: 11px;
        font-style: italic;
        line-height: 13px;
        margin-bottom: 5px;
      }

      .lineContainer {
        .line {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            bottom: 3px;
            left: 0;
            width: 100%;
            border-top: 1px dotted $color-grey-lighter;
          }

          .key,
          .value {
            background: $color-alabaster;
            z-index: 1;
            line-height: 20px;
          }

          .key {
            color: $color-grey-1;
            font-size: 14px;
            font-weight: bold;
            padding-right: 5px;
            position: relative;
          }

          .value {
            font-size: 16px;
            font-weight: 500;
            position: absolute;
            right: 0;
            top: 0;
            padding-left: 5px;
          }
        }

        .amount {
          font-size: 11px;
          line-height: 13px;
          margin-bottom: 5px;
        }

        &.total {
          .amount {
            display: none;
          }

          .line {
            border-top: 1px solid $color-grey-1;
            margin-top: 6px;
          }
        }
      }
    }

    .overdue {
      border-radius: 5px;
      border: 1px solid $color-red;
      color: $color-red;
      background-color: #fbcbc0;
      margin-top: 10px;
      padding: 6px;
      font-size: 13px;
      text-align: left;

      span {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .billing {
      margin-top: 20px;
      margin-bottom: 35px;
      padding: 0 10px;
      text-align: left;

      .credit {
        margin-bottom: 8px;
        font-size: 13px;
      }

      .billingTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        border-bottom: 1px solid $color-grey-light;

        .paymentMethod {
          font-size: 11px;
          cursor: pointer;

          @include primary-color();

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .billingName,
      .billingInfo {
        font-size: 12px;
        line-height: 15px;
      }

      .billingName {
        font-weight: bold;
        margin-bottom: 8px;
      }

      .billingAddress {
        flex: 1;
      }

      .billingInfo {
        display: flex;
        // align-items: flex-end;
        justify-content: space-between;
      }

      .billingNum {
        text-align: right;
        width: 140px;
      }

      .cvv {
        display: flex;
        align-items: center;
        margin-top: 8px;

        label {
          margin-right: 5px;
        }

        input {
          width: 55px;
        }
      }
    }
  }
}
